import * as types from '../actions/actionTypes';

export default function configReducer(state: any = {}, action: any) {
    switch (action.type) {
        case types.CONFIG_SET:
            console.log('Reducer: CONFIG_SET');
            return { ...state, ...action.config, isLoaded: true };
        default:
            return state;
    }
}