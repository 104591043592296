import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IDialogPropTypes {
  isDialogOpen: any;
  closeDialog: any;
  title?: any;
  content: React.ReactNode | string;
  actions?: React.ReactNode | string;
  label?: any;
  className?: string;
  [rest: string]: any;
}

const Dialog = ({
  isDialogOpen,
  closeDialog,
  title,
  content,
  actions,
  label,
  className,
  ...rest
}: IDialogPropTypes) => {
  return (
    <MuiDialog
      color="primary"
      open={isDialogOpen}
      onClose={closeDialog}
      aria-labelledby={title || ''}
      aria-describedby={label || ''}
      className={className}
      // style={{ zIndex: 9999 }}
      {...rest}>
      <DialogTitle style={{ paddingBottom: 0, paddingTop: '24px' }}>{title || ''}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions className="margin-1">{actions}</DialogActions>}
    </MuiDialog>
  );
};

export default Dialog;
