import React from 'react';
import { connect } from 'react-redux';
import './Logo.scss';

function Logo(props: any) {
  if (props.configState.logoSmall) {
    return (
      <div className="px-c-logo">
        <img src={`${props.configState.logoSmall}`} alt="Logo" />
        <div className="px-logoReferer"></div>
      </div>
    );
  } else {
    return <div className="px-c-logo">Logo</div>;
  }
}

function mapStateToProps(state: any) {
  return {
    configState: state.configState,
  };
}

export default connect(mapStateToProps)(Logo);
