import React from "react";
import JSONInput from "react-json-editor-ajrm";
import { PrimaryButton, TextField } from "../../components/AtomComponents";
import useDataService from "../../hooks/useDataService";
import { eMessageType } from "../../types/IMessageType";
import { localeEn } from "../ConfigTenant/ConfigTenant";
import { Wait } from "../SchemaSync/SchemaSync";
import { checkForSuccesInvocation } from "../../_lib/utils";


interface IConfigFieldProps {
  releaseAutomation: (
    versionCode: string,
    PRDescription: string,
    endpoint: string,
    userConfig?: Record<string, string>
  ) => any;
  endpoint: string;
  type: "prod" | "test" | "release";
}

const configuration = {
  prod: {
    buttonLabel: "Create PR for prod",
    isPRDescriptionRequired: true,
    isApproveAndMergeRequired: true,
    isConfigTextFieldOnlyRequired: false,
    destination: "prod",
  },
  test: {
    buttonLabel: "Create branch",
    isPRDescriptionRequired: false,
    isApproveAndMergeRequired: false,
    isConfigTextFieldOnlyRequired: true,
    destination: "",
  },
  release: {
    buttonLabel: "Create PR for RC",
    isPRDescriptionRequired: true,
    isApproveAndMergeRequired: true,
    isConfigTextFieldOnlyRequired: false,
    destination: "release/uat",
  },
};

const buttonConfig = {
  prod: "prod",
  release: "RC"
}

export const ConfigField = ({
  releaseAutomation,
  endpoint,
  type,
}: IConfigFieldProps) => {
  const [versionCode, setVersionCode] = React.useState("");

  const [PRDescription, setPRDescription] = React.useState("");
  const [processState, setProcessState] = React.useState<number>(0);
  const [previewState, setPreviewState] = React.useState<any>(null);
  const [userConfig, setUserConfig] = React.useState<any>({});
  const { openSnackBar } = useDataService();

  const onClick = async (name: string) => {
    setProcessState(1);
    const { isApproveAndMergeRequired, isPRDescriptionRequired, isConfigTextFieldOnlyRequired, destination } = configuration[type];
    const defaultPRDescription = isPRDescriptionRequired
      ? (PRDescription || `Release ${versionCode} to ${type}`)
      : "";

    const finalUserConfig = { ...userConfig, Destination: destination };

    const versionOrEmpty = (name !== "pr" && isApproveAndMergeRequired) ? "" : versionCode;
    const prDescriptionOrEmpty = (name !== "pr" && isApproveAndMergeRequired) ? "" : defaultPRDescription;
    const endpointValue = isApproveAndMergeRequired ? name : endpoint;
    const userConfigOrNull = isApproveAndMergeRequired ? finalUserConfig : isConfigTextFieldOnlyRequired ? userConfig : null;

    const res = await releaseAutomation(
      versionOrEmpty,
      prDescriptionOrEmpty,
      endpointValue,
      userConfigOrNull
    );
    if (!res.error) {
      try {
        checkForSuccesInvocation(res, setPreviewState, setProcessState)
      } catch (e: any) {
        openSnackBar(
          e.message || 'Something went wrong,Unable to check status',
          eMessageType.error
        );
      }
    } else {
      restart();
      openSnackBar(res.message ? res.message : 'Something went wrong', eMessageType.error);
    }
  };

  const updateUserConfig = (event: any) => {
    setUserConfig((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  const restart = () => {
    setProcessState(0);
    setPreviewState(null);
  };

  const isDisableButton = (name: string) => {
    const isApproveAndMergeRequired = configuration[type].isApproveAndMergeRequired;
    if(isApproveAndMergeRequired || configuration[type].isConfigTextFieldOnlyRequired) {
      if(name === "pr") {
        return !versionCode.length || !userConfig.BitBucketUser || !userConfig.AppPassword;
      } else {
        return !userConfig.BitBucketUser || !userConfig.AppPassword;
      }
    }
    return !versionCode.length;
  }

  return (
    <div className="margin-top-3">
      {processState === 0 && (
        <>
          <TextField
            label="Version Code"
            size="small"
            value={versionCode}
            onChange={(e) => {
              setVersionCode(e.target.value);
            }}
            placeholder="Version Code"
            className="margin-right-1 margin-bottom-1"
          />

          {configuration[type].isPRDescriptionRequired && (
            <TextField
              label="PRDescription"
              size="small"
              value={PRDescription}
              onChange={(e) => {
                setPRDescription(e.target.value);
              }}
              placeholder="PRDescription"
              className="margin-right-1 margin-bottom-1"
            />
          )}

          <PrimaryButton disabled={isDisableButton("pr")} onClick={() => onClick("pr")}>
            {configuration[type].buttonLabel}
          </PrimaryButton>
          {configuration[type].isApproveAndMergeRequired && (
            <>
            <PrimaryButton disabled={isDisableButton("approve")} className="margin-left-1" onClick={() => onClick("approve")}>
              Approve PR for {buttonConfig[type as "release" | "prod"]}
            </PrimaryButton>
            <PrimaryButton disabled={isDisableButton("merge")} className="margin-left-1" onClick={() => onClick("merge")}>
              Deploy to {buttonConfig[type as "release" | "prod"]}
            </PrimaryButton>
          </>
          )}

          {(configuration[type].isApproveAndMergeRequired || configuration[type].isConfigTextFieldOnlyRequired) && (
            <div className="margin-top-1">
              <TextField
                label="Bitbucket Username"
                size="small"
                name="BitBucketUser"
                value={userConfig.BitBucketUser || ""}
                onChange={updateUserConfig}
                placeholder="Bitbucket Username"
                className="margin-right-1"
              />
              <TextField
                label="App password"
                size="small"
                value={userConfig.AppPassword || ""}
                name="AppPassword"
                onChange={updateUserConfig}
                placeholder="App Password"
                className="margin-right-1"
              />
            </div>
          )}

        </>
      )}
      {processState === 1 && <Wait />}
      {processState === 2 && (
        <div className="margin-bottom-3">
          <JSONInput
            id="jsonEditor"
            theme="light_mitsuketa_tribute"
            locale={localeEn}
            placeholder={previewState}
            height="550px"
            width="100%"
            viewOnly={true}
            style={{ body: { fontSize: "16px" } }}
          />
        </div>
      )}
    </div>
  );
};
