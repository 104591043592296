import * as types from '../actions/actionTypes';

const initState = {
    error: null,
    success: null,
    message: null
}

export default function messageReducer(state: any = initState, action: any) {
    switch (action.type) {
        case types.ERROR_MESSAGE:
            return { success: false, error: true, message: action.message };
        case types.SUCCESS_MESSAGE:
            return { success: true, error: false, message: action.message };
        case types.REMOVE_MESSAGE:
            return { message: null, error: null, success: null }
        default:
            return state;
    }
}