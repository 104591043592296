import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import * as types from './actions/actionTypes';
import { preFilterMiddleware } from './middlewares/preFiltering';

declare const window: any;

const actionSanitizer = (action: any) =>
  action.type === types.OFFER_CLEARING_SET && action.payload
    ? { ...action, payload: action.payload }
    : action;

const stateSanitize = (state: any) =>
  state.results ? { ...state, results: state.results } : state;

export default function configureStore(initialState: any) {
  const composeEnhancers =
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          actionSanitizer,
          stateSanitize,
          trace: true,
          traceLimit: 10,
        })
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        preFilterMiddleware
        // reduxImmutableStateInvariant()
      )
    )
  );
}
